.tech{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    

    img{
        height: 60px !important;
    }


    h2{
        overflow: hidden;
    }

    h5{
        overflow: hidden;
    }
    .alazea-progress-bar {
        position: relative;
        z-index: 1;
        margin-top: 40px; 

        .single_progress_bar {
            position: relative;
            z-index: 1;
            margin-bottom: 30px; 
      

            &:last-child{
                margin-bottom: 0;
            }

            p{
                font-weight: 500;
                line-height: 1;
                font-size: 18px;
                color: #303030;
                margin-bottom: 10px; 
                margin-right: 200px;
                overflow: hidden;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
            }
            h6{
                font-size: 14px;
                font-weight: 350;
                overflow: hidden;
            }
            .barfiller {
                background-color: #ebebeb;
                border: none;
                border-radius: 0;
                box-shadow: none;
                height: 10px;
                margin-bottom: 5px;
                position: relative;
                width: 100%; 
            
                .fill {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: #0C9463;
                    z-index: 1; 
                 
                }
                .tipWrap {
                    display: none; 
                    .tip {
                        margin-top: -35px;
                        padding: 2px 4px;
                        font-size: 18px;
                        color: #303030;
                        left: 0;
                        position: absolute;
                        z-index: 2;
                        background: transparent;
                        font-weight: 500; 

                        &::after {
                            display: none; }
                    }

                }
            }
            .barfillerK {
                background-color: #ebebeb;
                border: none;
                border-radius: 0;
                box-shadow: none;
                height: 10px;
                margin-bottom: 5px;
                position: relative;
                width: 100%; 
            
                .fill {
                    display: block;
                    position: relative;
                    width: 50%;
                    height: 100%;
                    background: #0C9463;
                    z-index: 1; 
                 
                }
                .tipWrap {
                    display: none; 
                    .tip {
                        margin-top: -35px;
                        padding: 2px 4px;
                        font-size: 18px;
                        color: #303030;
                        left: 0;
                        position: absolute;
                        z-index: 2;
                        background: transparent;
                        font-weight: 500; 

                        &::after {
                            display: none; }
                    }

                }
            }
               
        }
        }
    }
    