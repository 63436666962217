*{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app{
  height: 100vh;

  .sections{
      width: 100%;
      height: calc(100vh - 60px) ; /* Navbar height'inee  70px verdiğimiz için taşırmamak adına böyle yapıyoruız*/
      background-color: lightsalmon;
      position: relative;
      top: 60px;
      scroll-behavior: smooth;
      scroll-snap-type: y mandatory;
      scrollbar-width: none; // for firefox
      &::-webkit-scrollbar{
          display: none;
      }

      > *{ /* Header portfolio gibi her bir eleman için */
          width: 100%;
          height: calc(100vh - 60px) ; 
          scroll-snap-align: start;
      }
  }
}