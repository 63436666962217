.services{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    h1{
        overflow: hidden;
   }

}

