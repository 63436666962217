.container{
    overflow: hidden;

    .row{
    overflow: hidden;

        a{
            text-decoration: none;
            display: flex;
            color: black !important;
        }

        img{
            height: 100px;
            width: 100px;
            object-fit: contain;
        }

        .text-side{
            display: flex;
            align-items: center;
        }

        h4{
            overflow: hidden;
            font-weight: 400;
                text-decoration: none;

        }
        p{
            color: rgb(150, 150, 150);
            font-size: 12px;
        }
    }
}