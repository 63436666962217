.contact{
    background: #0C9463;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;

    .container{
        width: 100%;
       
        display: flex;
        align-items: center;
        justify-content: center;

        form{
            background: #fff;
            display: flex;
            flex-direction: column;
            padding: 2vw 4vw;
            width: 90%;
            max-width: 600px;
            border-radius: 10px;

            h3{
                color: #555;
                font-weight: 800;
                margin-bottom: 20px;
                overflow: hidden;
            }

            input,
            textarea{
                border: 0;
                margin: 10px 0;
                padding: 20px;
                outline: none;
                background: #f5f5f5;
                font-size: 16px;
            }
            button{
                padding: 15px;
                background: #ff5361;
                color: #fff;
                font-size: 18px;
                border: 0;
                outline: none;
                cursor: pointer;
                width: 150px;
                margin: 20px auto 0;
                border-radius: 30px;
            }
        }
    }
}