.about{
    background-color: white;

    .card-body{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        cursor: pointer;
    }

    .active{
        
        .card-icon{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    h5{
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }

    h1,h3{
        overflow: hidden;
    }

   
}