@import '../../global.scss';

.navbar{
    width: 100%;
    height: 60px;
    top: 0;
    z-index: 3;
    position: absolute;

      .home-nav{
        margin-right: 30px;
        text-decoration: none;
        color: #1f2122;
      }

      .portfolio-nav{
        margin-right: 30px;
        text-decoration: none;
        color: #1f2122;
      }
        
      .blog-nav{
        margin-right: 30px;
        text-decoration: none;
        color: #1f2122;
      }

      .about-nav{
        margin-right: 100px;
        text-decoration: none;
        color: #1f2122;
      }
}  